import MapModule from './map/map.js'

function getParameterByName(name){
    var url = window.location.href
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    console.log(results[2].replace(/\+/g, " "))
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

class Map extends MapModule{
    constructor(options) {

        var $mapContainer = $('#js-map');
        if ($mapContainer.attr('data-region') == 'ge'){
            var lat = (getParameterByName('lat')) ? getParameterByName('lat') : $mapContainer.data('marker').lat ? $mapContainer.data('marker').lat : 41.71235;
            var lon = (getParameterByName('lng')) ? getParameterByName('lng') : $mapContainer.data('marker').lng ? $mapContainer.data('marker').lng : 44.8181145;
            var zoom = (getParameterByName('zoom')) ? getParameterByName('zoom') : $mapContainer.data('zoom') ? $mapContainer.data('zoom') : 10;
        }else{
            var lat = (getParameterByName('lat')) ? getParameterByName('lat') : $mapContainer.data('marker').lat ? $mapContainer.data('marker').lat : 47.025888;
            var lon = (getParameterByName('lng')) ? getParameterByName('lng') : $mapContainer.data('marker').lng ? $mapContainer.data('marker').lng : 28.834278;
            var zoom = (getParameterByName('zoom')) ? getParameterByName('zoom') : $mapContainer.data('zoom') ? $mapContainer.data('zoom') : 12;
        }

        var defaults = {
            document: document,
            mapPopup: '#popup-map',
            popupClose: '.js-close-popup',
            leaflet: {
                center: {
                    lat: lat,
                    lng: lon
                },
                zoom: zoom
            }
        };

        options = $.extend(options, defaults);

        super(options);

        this.init(options.leaflet)
        this.events();
    }

    init(leaflet) {

        this.map.setView({"lat": leaflet.center.lat, "lng": leaflet.center.lng}, leaflet.zoom);
    }

    events() {
        var _this = this;

        $(_this.options.document).on('click', _this.options.popupClose, function(e){
            $(this).closest(_this.options.mapPopup).empty();
        })
    }
}

export default Map;
